<template>
  <div class="student-detail">
    <div class="d-container" v-loading="loading">
      <div class="d-main">
        <el-row class="d-info d-item">
          <el-col :span="3">
            <el-image
              :src="imageSrc(userInfo.user_avatar, userInfo.gender)"
            ></el-image>
            <div class="Real" v-if="userInfo.user_auth_state == 3">
              <span>已实名</span>
            </div>
          </el-col>
          <el-col :span="21" class="i-content">
            <div class="i-name">{{ userInfo.user_nickname}}</div>
            <ul class="i-info">
              <li class="i-item">{{ userInfo.gender | genderFormat }}</li>
              <li class="i-item" v-if="userInfo.id_cart_num">{{ userInfo.id_cart_num }}岁</li>
              <li class="i-item" v-if="userInfo.height">{{ userInfo.height }}cm</li>          
              <li class="i-item" v-if="userInfo.min_salary_range == 0 || userInfo.min_salary_range == null ">面议</li>
              <li class="i-item" v-else>{{userInfo.min_salary_range}}-{{userInfo.max_salary_range}}k</li>
              <li class="i-item" v-if="userInfo.education_level">{{ userInfo.education_level }}</li>
              <li class="i-item" v-if="userInfo.job_wanted == 1">正在找工作</li>
              <li class="i-item" v-if="userInfo.job_wanted == 0">暂不找工作</li>
              <!-- <li class="i-item" v-if="userInfo.job_wanted == 2">在职状态：在校生兼职</li> -->
              <!-- <li class="i-item">身高：{{ userInfo.height }}</li>
              <li class="i-item">学历：{{ userInfo.education_level }}</li> -->
            </ul>
          </el-col>
        </el-row>
        <div class="d-experience d-item">
          <h1>自我介绍</h1>
          <div class="e-container">
            {{userInfo.introduction}}
          </div>
        </div>
        <div class="d-experience d-item">
          <h1>工作经验</h1>
          <div class="e-container">
            <el-row
              class="c-company"
              v-for="(item, index) in WorkList"
              :key="index"
            >
              <el-col :span="6">{{ item.corporate }}</el-col>
              <el-col :span="6">{{ item.post }}</el-col>
              <el-col :span="6">{{ item.wsalary }}</el-col>
              <el-col :span="6">
                {{ item.start_time }}至{{ item.end_time }}
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="d-education d-item">
          <h1>教育经历</h1>
          <div class="e-container">
            <el-row
              class="c-company"
              v-for="(item, index) in educationList"
              :key="index"
            >
              <el-col :span="6">{{ item.school }}</el-col>
              <el-col :span="6">{{ item.major }}</el-col>
              <el-col :span="6">{{ item.education }}</el-col>
              <el-col :span="6">
                {{ item.start_time }}至{{ item.end_time }}
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="d-education d-item" v-if="cartInfo.length>0">
            <h1>证书</h1>
            <div class="e-container">
              <el-row class="c-company">
                <el-tag v-for="item in cartInfo" :key="item.id" type="danger" effect="dark" style="margin-right: 10px;">
                  {{ item.name }}
                </el-tag>
              </el-row>
            </div>
          </div>
        <div class="d-btn">
          <el-button icon="el-icon-back" size="small" @click="$router.go(-1)"
            >返回
          </el-button>
          <div v-if="userInfo.user_phone" class="userPhone">{{userInfo.user_phone}}</div>
          <div v-else style="margin-left: 20px"><look-phone :userId="userInfo.user_id"></look-phone></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    getStdDetailDataApi
  } from '@/api/home'
  import lookPhone from '@/components/lookPhone/index.vue'
  export default {
    name: "studentDetail",
    components: {
      lookPhone
    },
    data() {
      return {
        user_id: '',
        loading: false,
        userInfo: {
            real_name:''
        }, //用户信息
        educationList: [],
        WorkList: [],
        cartInfo:[]
      }
    },
    filters: {
      genderFormat(val) {
        if (val == 1) {
          return val = '男'
        } else if (val == 2) {
          return val = '未知'
        } else {
          return val = '女'
        }
      }
    },
    created() {
      this.user_id = this.$route.params.user_id
      this.getStdDetailData()
    },
    methods: {
      async getStdDetailData() {
        this.loading = true
        const {
          data: res
        } = await getStdDetailDataApi(this.user_id)
        if (res.status != 10000) {
          return this.$message.error('获取学员详情数据失败')
        }
        this.userInfo = res.result.info
        this.educationList = res.result.info.userEducation
        this.WorkList = res.result.info.userWorklist
        this.cartInfo = res.result.info.cartInfo
        this.loading = false
      },
      imageSrc(ID_Photo, gender) {
        if (ID_Photo) {
          return ID_Photo
        } else if (ID_Photo == null && gender == 0) {
          return require('../../../../assets/images/woman.jpg')
        } else if (ID_Photo == null && gender == 1) {
          return require('../../../../assets/images/man.jpg')
        }
      },

    },
  }

</script>

<style lang="less" scoped>
/deep/.el-dialog__header {
  background: #fff !important;
}

.student-detail {
  .d-container {
    width: 1200px;
    margin: 0 auto;

    .d-main {
      margin: 20px 0px;
      min-height: 80vh;
      background: #fff;

      .d-item {
        padding: 40px;
        margin: 0px 20px;
        border-bottom: 1px dashed #eaeaea;
      }

      .d-info {
        .el-image {
          width: 60px;
          height: 60px;
          border-radius: 100%;
          vertical-align: middle;
          margin: -3px 0 0 10px;
          border: none;
          text-align: right;
        }

        .i-content {
          height: 60px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .i-name {
            font-weight: 600;
          }

          .i-info {
            margin-top: 10px;
            display: flex;
            color: #8d92a1;
            font-size: 14px;

            .i-item {
              // border-left: 1px solid #8d92a1;
              margin-right:10px;
              // height: 10px;
              line-height: 1.5;
            }

            .i-item:last-child {
              margin-right: 0px;
              border-left: none;
            }
          }
        }
      }

      .d-experience {
        .e-container {
          margin-top: 20px;
          font-size: 13px;
          .c-company {
            display: flex;
            .el-col {
              padding: 10px 0px;
            }
          }
        }
      }

      .d-education {
        .e-container {
          margin-top: 20px;
          font-size: 13px;

          .c-company {
            display: flex;
            .el-col {
              padding: 10px 0px;
            }
          }
        }
      }

      .d-btn {
        padding: 40px;
        display: flex;
        justify-content: center;
      }
    }
  }

  /deep/ .el-dialog__body {
    padding: 0px;
    margin: 0px;
  }

  /deep/.el-dialog__wrapper {
    top: 20%;
  }

  .userPhone{
    margin-left: 20px;
    text-align: center;
    font-size: 16px;
    color: red;
    font-weight: bold;
    line-height: 34px;
  }
  .Real span{
    display: inline-block;
    padding: 1px 5px;
    color: #0092ff;
    font-size: 12px;
    line-height: 1.5;
    border: 1px solid #EBEEF5;
    margin: 5px 0 0 13px;
  }
}
</style>
